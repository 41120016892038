h2 {
    margin-top: 2em;
    margin-bottom: 2em;
}

.navbar + .login-container {
    padding-top: 168px;
}

.login-container{
    width: 100%;
    height: 100%;
    padding-left: 70px;
    padding-right: 70px; 
    justify-content: center;
    align-items: flex-start;
    gap: 70px; 
    display: inline-flex;
}

.login-form-container{
    height: auto; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 28px; 
    display: inline-flex;
}

.login-input-container{
    justify-content: flex-start; 
    align-items: flex-start;
    display: inline-flex;
}

.login-inputs {
    width: 474px;
    height: auto; 
    padding-left: 26px; 
    padding-right: 26px; 
    padding-top: 24px; 
    padding-bottom: 24px; 
    background-color: #FFFFFF; 
    border-radius: 6px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    gap: 21px; 
    display: inline-flex;
}

.ant-form-item .ant-form-item-label > label {
	position: relative;
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	height: 19px;
	color: #000000;
	font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal; 
}

.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none;
}

.ant-input {
	border: none;
    box-shadow: none;
    padding: 0;
    color: #60D473;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    width: 100%;
}

.ant-input:hover {
    border: none;
    box-shadow: none;
    padding: 0;
}

.ant-input:focus {
    border: none;
    box-shadow: none;
    padding: 0;
}

.ant-input::placeholder {
    color: #60D473;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

.ant-input-affix-wrapper {
    border: none;
    box-shadow: none;
    padding: 0;
	border: none;
    box-shadow: none;
    padding: 0;
    color: #60D473;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

.ant-input-affix-wrapper:hover{
    border: none;
    box-shadow: none;
    padding: 0;
}

.ant-input-affix-wrapper:focus{
    border: none;
    box-shadow: none;
    padding: 0;
}

.ant-input-affix-wrapper::placeholder{
    color: #60D473;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

.login-submit-btn {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    display: flex;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	padding: 0;
    border: none;
    width: 300px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover {
    padding: 0;
    border: none;
    box-shadow: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:active {
    padding: 0;
    border: none;
    box-shadow: none;
}

.ant-select:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input):not(.ant-pagination-size-changer):active .ant-select-selector, .ant-select:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
	border: none;
	box-shadow: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
    padding: 0;
    border: none;
    box-shadow: none;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	color: #60D473;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

.ant-select .ant-select-arrow {
	display: none;
}

.ant-select:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border: none;
    box-shadow: none;
}

.ant-select:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input):not(.ant-pagination-size-changer):active .ant-select-selector {
    border: none;
    box-shadow: none;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
	position: absolute;
	top: 0;
	inset-inline-start: 0; 
    inset-inline-end: 0; 
	bottom: 0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	height: auto; 
}

.ant-select-single .ant-select-selector {
    color: #60D473;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}