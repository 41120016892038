.info-pop-up {
    width: auto;
    height: auto;
    padding: 10px 13px;
    background: rgba(13.81, 13.81, 13.81, 0.75);
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    display: inline-flex;
}

.info-pop-up-item {
    width: 200px; 
    height: 19px;
    position: relative;
}

.info-pop-up-title {
    left: 0; 
    top: 0; 
    position: absolute;
    color: #ffffff;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    word-wrap: break-word;
}

.info-pop-up-text {
    right: 0;
    top: 0;
    position: absolute;
    color: #ffffff;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 500;
    word-wrap: break-word;
}