.loading-page {
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: flex-start; 
    display: inline-flex;
}

.loading-page-container {
    width: 900px;
    height: 100%;
    padding: 100px 0;
    flex-direction: column; 
    justify-content: flex-start; 
    align-items: center;
    gap: 28px; 
    display: inline-flex;
}

.loading-page-info-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 42px;
    display: flex
}

.loading-page-info-title {
    width: 450px;
    text-align: center;
    color: #0E0E0E;
    font-size: 32px;
    font-family: "Roboto";
    font-weight: 700;
    word-wrap: break-word;
}

.loading-page-text-container {
    align-self: stretch;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 11px;
    display: flex
}

.loading-page-text-subtitle {
    align-self: stretch;
    text-align: center;
    color: #0E0E0E;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 500;
    word-wrap: break-word;
}

.loading-page-text {
    align-self: stretch;
    text-align: center;
    color: #0E0E0E;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    word-wrap: break-word;
}