.no-content-container {
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 28px;
    display: inline-flex;
}

.no-content-info {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 42px; 
    display: flex;
}

.no-content-info-title {
    width: 450px;
    color: #0E0E0E;
    font-size: 32px;
    font-family: "Roboto";
    font-weight: 700;
    word-wrap: break-word;
}

.no-content-info-text-container {
    align-self: stretch;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 11px;
    display: flex;
}

.no-content-info-text-subtitle {
    align-self: stretch;
    color: #0E0E0E; 
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 500;
    word-wrap: break-word;
}

.no-content-info-text {
    align-self: stretch;
    color: #0E0E0E;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    word-wrap: break-word;
}