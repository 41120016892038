.drag-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    width: 100%;
    max-width: 600px;
    height: 200px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    cursor: pointer;
    color: #ccc;
}
.drag-area.active {
    border-color: #000;
    color: #000;
}