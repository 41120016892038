.spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.submit-btn:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
}