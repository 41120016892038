.navbar + .main-panel-with-footer {
    padding-top: 164px;
}

.main-panel-with-footer {
    width: 100%;
    height: auto;
    min-height: 100%;
    max-height: 100%;
    background-color: #F2F0F0;
}

.main-panel-container {
    width: 100%;
    padding-left: 70px; 
    padding-right: 70px; 
    height: auto;
    justify-content: flex-start;
    align-items: flex-start; 
    gap: 70px; 
    display: inline-flex;
    background-color: #F2F0F0;
}

.video-page-container {
    width: auto;
    margin: auto;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    overflow: scroll;
}

.video-container {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 28px;
    display: inline-flex;
}

.video-name {
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 500;
    word-wrap: break-word;
    text-transform: capitalize;
}

.table-container {
    width: 100%;
    display: block;
    overflow: scroll;
}

.table {
	--bs-table-bg: unset; 
    border-collapse: collapse;
}

.table > thead {
    border-bottom: #08142B4D solid 2px;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #F2F0F0;
}

thead, tbody > tr {
    border-bottom: #08142B4D solid 1px;
}

.table > thead > tr > th {
    font-size: 20px; 
    font-family: "Roboto";
    font-weight: 700;
    word-wrap: break-word;
    height: 80px;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    padding: 0 85px 0 0;
}

.table > tbody > tr > td {
    font-size: 16px;
    font-family: "Roboto"; 
    font-Weight: 500; 
    word-wrap: break-word;
    text-align: left;
    padding-left: 0;
}

.table > tbody > tr:last-child td {
    border: none;
}

.table-action-buttons {
    margin-right: 20px;
}

.table-information-panel {
    position: relative;
}

.info-pop-up {
    position: absolute;
    right: 90px;
}

.info-pop-up-img {
    width: 36px;
    max-height: 36px;
    border-radius: 50%;
    opacity: 0.50
}

.form-container {
    width: auto;
    height: var(--form-height);
    overflow: scroll;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 28px;
    display: inline-flex;
}

.form-text-panel {
    justify-content: flex-start;
    align-items: flex-start; 
    gap: 50px; 
    display: inline-flex;
}

.form-main-panel {
    width: 474px;
    height: auto; 
    padding-left: 26px;
    padding-right: 26px; 
    padding-top: 24px;
    padding-bottom: 24px;
    background: #ffffff;
    border-radius: 6px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 21px;
    display: inline-flex;
}

.form-item {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    gap: 12px; 
    display: flex;
}

.form-horizon-sub-item {
    align-self: stretch; 
    justify-content: flex-start; 
    align-items: flex-start; 
    gap: 10px;
    display: inline-flex;
}

.form-label {
    line-height: 19px;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300; 
    word-wrap: break-word;
    margin: 0;
}

.form-text {
    line-height: 19px;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 500;
    word-wrap: break-word;
}

.ant-slider .ant-slider-handle::before {
	content: "";
	position: absolute;
	inset-inline-start: -2px;
	inset-block-start: -2px;
	width: 14px;
	height: 14px;
	background-color: transparent;
}

.ant-slider .ant-slider-handle::after {
	content: "";
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	width: 10px;
	height: 10px;
	background-color: #ffffff;
	box-shadow: var(--slider-border-color);
	border-radius: 50%;
	cursor: pointer;
	transition: inset-inline-start 0.2s,inset-block-start 0.2s,width 0.2s,height 0.2s,box-shadow 0.2s;
}

.ant-slider:hover .ant-slider-handle::after {
	box-shadow: var(--slider-shadow-color);
}

.ant-slider .ant-slider-handle:hover::after, .ant-slider .ant-slider-handle:active::after, .ant-slider .ant-slider-handle:focus::after {
	box-shadow: var(--slider-shadow-color);
	width: 12px;
	height: 12px;
	inset-inline-start: -1px;
	inset-block-start: -1px;
}

.ant-slider.ant-slider-disabled .ant-slider-handle::after {
	background-color: #ffffff;
	cursor: not-allowed;
	width: 10px;
	height: 10px;
	box-shadow: var(--slider-border-color);
	inset-inline-start: 0;
	inset-block-start: 0;
}

.ant-tooltip{
    z-index: 9;
}

input#send-proposal_startTime::placeholder {
    color: var(--date-default-color);
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 500;
}

input#send-proposal_startTime {
    color: var(--date-default-color);
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 500;
}

input#send-proposal_endTime::placeholder {
    color: var(--date-default-color);
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 500;
}

input#send-proposal_endTime {
    color: var(--date-default-color);
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 500;
}

.ant-picker {
    padding: 0;
}

.ant-picker .ant-picker-input > input:hover {
	cursor: pointer;
}

.ant-picker-focused {
    box-shadow: none;
	border: none;
	outline: 0;
}

.ant-picker {
	border: none;
}

.ant-picker .ant-picker-suffix {
	display: none;
}

.form-info-panel {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    gap: 12px;
    display: inline-flex;
}

.form-info-item {
    width: 310px;
    height: 19px;
    position: relative;
}

.form-info-label {
    line-height: 19px;
    width: 200px; 
    left: 0px;
    top: 0px;
    position: absolute;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    word-wrap: break-word;
}

.form-info-text {
    line-height: 19px;
    width: 110px; 
    left: 200px;
    top: 0; 
    position: absolute; 
    text-align: right; 
    font-size: 16px;
    font-family: "Roboto"; 
    font-weight: 500;
    word-wrap: break-word;
}

.form-comment-panel {
    flex-direction: column; 
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px; 
    display: flex
}

.form-comment-label {
    font-size: 16px;
    font-family: "Roboto";
    font-weight: "300";
    word-wrap: break-word;
}

.form-comment-text {
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 500;
    word-wrap: break-word;
}

.form-button-panel {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    display: inline-flex;
}

.upload-content-btn {
    padding: 0 70px; 
    margin-bottom: 28px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}